import axios from 'axios'
import Cookies from 'js-cookie'

axios.defaults.baseURL = process.env.VUE_APP_API_URL || 'https://cdn.likeeng.uk/api/v1'

axios.interceptors.request.use(function (config) {
  const token = Cookies.get('token')
  if (!!token) config.headers.Authorization = `Bearer ${token}`
  config.maxContentLength = 500000000
  config.maxBodyLength = 5000000000
  return config;
}, function (error) {
  return Promise.reject(error);
});

export default axios