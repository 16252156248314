import axios from '@/axios.js'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    countries (context, payload) {
      let config = {}
      if (!!context.rootState.user.user.token) {
        config = {
          headers: {
            Authorization: `Bearer ${context.rootState.user.user.token}`
          },
          params: {
            name: payload
          }
        }
      }
      return new Promise ((resolve, reject) => {
        axios.get('/dictionary/countries', config)
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    cities (context, payload) {
      let config = {}
      if (!!context.rootState.user.user.token) {
        config = {
          headers: {
            Authorization: `Bearer ${context.rootState.user.user.token}`
          },
          params: {
            name: payload
          }
        }
      }
      return new Promise ((resolve, reject) => {
        axios.get('/dictionary/cities', config)
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    async categories (context, payload) {
      try {
        const response = await axios.get('/dictionary/categories')
        return response
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    }
  },
}