import axios from '@/axios.js'
import Vue from 'vue'
import Cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    // Boolean
    auth: false,
    user: {
      email: null,
      username: null,
      type: null,
      token: null,
      uuid: null
    },
    settings: null
  },
  getters: {
    USER_DATA (state) {
      return state.user
    },
    PHOTO_URL (state) {
      if (state.settings !== null) return state.settings.photo.preview
      return false
    },
    HAS_AUTH (state) {
      return state.auth
    },
    SETTINGS (state) {
      return state.settings
    }
  },
  mutations: {
    LOGOUT (state, payload) {
      Cookies.remove('token')
      Cookies.remove('uuid')
      state.auth = false
      state.user.token = null
      state.user.uuid = null
    },
    SET_USER_DATA (state, payload) {
      if (!!payload.type) Cookies.set('usertype', payload.type)
      if (!!payload.email) Cookies.set('useremail', payload.email)
      if (!!payload.username) Cookies.set('username', payload.username)
      if (!!payload.token) {
        Cookies.set('token', payload.token)
        state.auth = true
      }
      if (!!payload.uuid) Cookies.set('uuid', payload.uuid)

      let defaultObj = {
        email: null,
        username: null,
        type: null,
        token: null,
        uuid: null
      }

      let userObj = {
        ...defaultObj,
        ...payload
      }

      state.user = userObj
    },
    SET_SETTINGS (state, payload) {
      state.settings = payload
    },
    SET_USER_PHOTO (state, payload) {
      if (state.settings === null) {
        state.settings = {
          photo: {
            preview: payload
          }
        }
        Vue.set(state.settings.photo, 'preview', payload)
      } else {
        Vue.set(state.settings.photo, 'preview', payload)
      }
    }
  },
  actions: {
    // Auth header added in axios.js
    async signup ({ commit }, payload) {
      commit('SET_USER_DATA', { ...payload })
      return new Promise((resolve, reject) => {
        axios.post('/auth/signup', payload)
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    async signin ({ state, commit }, payload) {
      console.log('before TRY')
      try {
        const response = await axios.post('/auth/login', payload)
        commit('SET_USER_DATA', {
          uuid: response.data.data.uuid,
          token: response.data.data.token
        })
        console.log('res', response)
        return response
      }
      catch (e) {
        console.log(e)
        return Object.assign({}, e).response
      }
    },
    settings ({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/user/settings')
        .then((response) => {
          commit('SET_SETTINGS', response.data.data.data)
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    updateSettings ( { state, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.put('/user/settings', payload)
        .then((response) => {
          dispatch('settings')
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    getUserData ({ state, commit, dispatch }, uuid) {
      return new Promise((resolve, reject) => {
        axios.get(`/user/${uuid}`)
        .then((response) => {
          dispatch('settings')
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },

    getPartnerData ({ state, commit, dispatch }, uuid) {
      return new Promise((resolve, reject) => {
        axios.get(`/partner/${uuid}`)
        .then((response) => {
          dispatch('settings')
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },

    
    follow ({ state, commit, dispatch }, uuid) {
      return new Promise((resolve, reject) => {
        axios.put(`/user/${uuid}/follow`, { follow: true })
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    unfollow ({ state, commit, dispatch }, uuid) {
      return new Promise((resolve, reject) => {
        axios.put(`/user/${uuid}/follow`, { follow: false })
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    uploadPhoto ({ state, commit }, formdata) {
      return new Promise ((resolve, reject) => {
        axios.put('user/photo/upload', formdata, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then((res) => {
          resolve(res)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    confirm ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.put('/auth/confirm', payload)
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    recovery ({ commit }, email) {
      return new Promise((resolve, reject) => {
        axios.put('/auth/forgot', { email })
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    recoverySendCode ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.put('/auth/password', payload)
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    resendConfirmCode ({ commit }, email) {
      return new Promise ((resolve, reject) => {
        axios.put('/auth/signup/code-repeat', { email })
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    async setFriend ({ commit }, uuid) {
      try {
        let result = await axios.put(`/user/${uuid}/friend`)
        return result
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    },
    async removeFriend ({ commit }, uuid) {
      let result = await axios.put(`/user/${uuid}/friend`)
      return result
    },
    async acceptFriend ({ commit }, uuid) {
      let result = await axios.put(`/user/request/${uuid}/accept`)
      return result
    },
    async rejectFriend ({ commit }, uuid) {
      let result = await axios.put(`/user/request/${uuid}/reject`)
      return result
    },
    logout ({ commit }) {
      commit('LOGOUT')
    },
    loadAuth(context) {
      const token = Cookies.get('token')
      const lang =  Cookies.get('LANG')
      if (!!token) {
        context.commit('SET_USER_DATA', {
          token
        })
        context.dispatch('settings')
      }
      if (!lang) {
        Cookies.set('LANG', 'en')
      }
    },
    setUserPhoto({ commit }, url) {
      commit('SET_USER_PHOTO', url)
    },

    async getFriends({ commit }, name) {
      let result = await axios.get(`/user/friends`)
      return result
    },


    /* NOTIFICATION */
    async notifications ({ commit }) {
      try {
        const response = await axios.get(`/user/notifications`)
        return response
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    },

    
    async changePassword ({ commit }, payload) {
      try {
        const response = await axios.put('/auth/change_password', payload)
        return response
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    },
    async getGallery (context, { cursor = null }) {
      return new Promise ((resolve, reject) => {
        let url = '/user/gallery'
        if (cursor !== null) {
          url =`${url}?cursor=${cursor}`
        }
        console.log(cursor)
        axios.get(url)
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    async getReferalStatistic (context, { cursor = null, filter = null }) {
      let url = '/user/statistic/referal'
      if (cursor !== null) {
        url =`${url}?cursor=${cursor}`
      }
      if (!!filter) {
        if (filter.end_date !== null && filter.start_date !== null) {
          let filterUrlPart = `start_date=${filter.start_date}&end_date=${filter.end_date}`
          url = (!!cursor) ? `${url}&${filterUrlPart}` : `${url}?${filterUrlPart}`
        }
      }

      try {
        const result = await axios.get(url)
        return result
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    },
    async getEarnings (context, { cursor = null, filter = null }) {
      let url = '/user/statistic/earnings'
      if (cursor !== null) {
        url =`${url}?cursor=${cursor}`
      }

      if (filter !== null) {
        let symbol = (cursor !== null) ? '&' : '?'
        url = `${url}${symbol}date=${filter}`
      }

      try {
        const result = await axios.get(url)
        return result
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    },
    async getScreenings (context, { cursor = null, filter = null }) {
      let url = '/user/statistic/screenings'
      if (cursor !== null) {
        url =`${url}?cursor=${cursor}`
      }

      if (filter !== null) {
        let symbol = (cursor !== null) ? '&' : '?'
        url = `${url}${symbol}date=${filter}`
      }

      try {
        const result = await axios.get(url)
        return result
      }
      catch (e) {
        console.log('e', Object.assign({}, e).response)
        return Object.assign({}, e).response
      }
    },
    async getEarningsSummary (context, { filter = null }) {
      let url = 'user/statistic/earnings/summary'
      if (filter !== null) {
        url =`${url}?date=${filter}`
      }
      try {
        const result = await axios.get(url)
        return result
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    },
    async getScreeningsSummary (context, { filter = null }) {
      let url = 'user/statistic/screenings/summary'
      if (filter !== null) {
        url =`${url}?date=${filter}`
      }
      try {
        const result = await axios.get(url)
        return result
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    },

    async getViews (context, { cursor = null, filter = null }) {
      let url = 'user/statistic/views'
      if (cursor !== null) {
        url =`${url}?cursor=${cursor}`
      }

      if (filter !== null) {
        let symbol = (cursor !== null) ? '&' : '?'
        url = `${url}${symbol}date=${filter}`
      }

      try {
        const result = await axios.get(url)
        return result
      }
      catch (e) {
        console.log('e', Object.assign({}, e).response)
        return Object.assign({}, e).response
      }
    },
    async getViewsSummary (context, { filter = null }) {
      let url = 'user/statistic/views/summary'
      if (filter !== null) {
        url =`${url}?date=${filter}`
      }
      try {
        const result = await axios.get(url)
        return result
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    },
    async getUserBalance () {
      let url = 'user/balance'
      try {
        const result = await axios.get(url)
        return result
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    },

    async getUserBalanceEarnings () {
      let url = 'user/balance/earnings'
      try {
        const result = await axios.get(url)
        return result
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    },

    async getThemes (context, { filter = null, category = false }) {
      let url = '/user/statistic/themes_categories'
      if (filter !== null) {
        url =`${url}?date=${filter}`
      }
      if (!!category) {
        url = (filter !== null) ? `${url}&name=${category}` : `${url}?name=${category}`
      }
      try {
        const result = await axios.get(url)
        return result
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    },

    async getPopulary (context, { cursor = null, filter = null }) {
      let url = '/user/statistic/populations'
      if (cursor !== null) {
        url =`${url}?cursor=${cursor}`
      }

      if (filter !== null) {
        let symbol = (cursor !== null) ? '&' : '?'
        url = `${url}${symbol}date=${filter}`
      }

      try {
        const result = await axios.get(url)
        return result
      }
      catch (e) {
        console.log('e', Object.assign({}, e).response)
        return Object.assign({}, e).response
      }
    },
    async getReferLink () {
      try {
        const result = await axios.get('/user/link')
        return result
      }
      catch (e) {
        console.log('e', Object.assign({}, e).response)
        return Object.assign({}, e).response
      }
    }
  },
}