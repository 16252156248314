import axios from '@/axios.js'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    filters: null,
    user_filters: null
  },
  getters: {
    GET_FILTERS (state) {
      return state.filters
    },
    GET_FILTERS_USER (state) {
      return state.user_filters
    }
  },
  mutations: {
    SET_FILTERS (state, { filters, type }) {
      if (type === 'user') {
        state.user_filters = filters
      } else {
        state.filters = filters
      }
    }
  },
  actions: {
    setFilters ({ commit }, { filters, type }) {
      commit('SET_FILTERS', { filters, type })
    },
    async feedFollowing (context, { cursor = null, filters = null }) {
      let url = '/user/feed/following'
      if (cursor !== null) {
        url =`${url}?cursor=${cursor}`
      }
      if (filters !== null) {
        if (cursor !== null) {
          url = `${url}&json=${encodeURI(JSON.stringify({filters: filters}))}`
        } else {
          url = `${url}?json=${encodeURI(JSON.stringify({filters: filters}))}`
        }
        
      }

      try {
        const result = await axios.get(url)
        return result
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    },
    async feedAdvert (context, { cursor = null, filters = null }) {
      let url = '/user/feed/adver'
      if (cursor !== null) {
        url =`${url}?cursor=${cursor}`
      }
      if (filters !== null) {
        if (cursor !== null) {
          url = `${url}&json=${encodeURI(JSON.stringify({filters: filters}))}`
        } else {
          url = `${url}?json=${encodeURI(JSON.stringify({filters: filters}))}`
        }
        
      }

      try {
        const result = await axios.get(url)
        return result
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    },
    async feedAll (context, { cursor = null , filters = null }) {

      let url = '/user/feed/all'
      if (cursor !== null) {
        url =`${url}?cursor=${cursor}`
      }
      if (filters !== null) {
        if (cursor !== null) {
          url = `${url}&json=${encodeURI(JSON.stringify({filters: filters}))}`
        } else {
          url = `${url}?json=${encodeURI(JSON.stringify({filters: filters}))}`
        }
        
      }

      try {
        const result = await axios.get(url)
        return result
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    },
    async userFeed ( context, { cursor = null, filters = null, uuid }) {

      let url = `/user/${uuid}/feed`
      if (cursor !== null) {
        url =`${url}?cursor=${cursor}`
      }
      if (filters !== null) {
        if (cursor !== null) {
          url = `${url}&json=${encodeURI(JSON.stringify({filters: filters}))}`
        } else {
          url = `${url}?json=${encodeURI(JSON.stringify({filters: filters}))}`
        }
        
      }

      try {
        const result = await axios.get(url)
        return result
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    },
    async uploadPhoto ({ commit }, payload) {
      try {
        
      }
      catch (e) {

      }
    },
    async createPost ({ commit }, payload) {
      try {
        const result = await axios.post('user/post', payload)
        console.log(result)
        return result
      }
      catch (e) {

      }
    },
    async uploadPostImages ({ commit }, { uuid, data }) {
      try {
        const url = `/user/post/${uuid}/image/upload`
        const result = await axios.post(url, data)
        return result
      }
      catch (e) {

      }
    },
    async uploadPostVideos ({ commit }, { uuid, data }) {
      try {
        const url = `/user/post/${uuid}/video/upload`
        const result = await axios.post(url, data)
        return result
      }
      catch (e) {

      }
    },
    async publishPost ({ commit }, uuid) {
      try {
        const result = await axios.put(`/user/post/${uuid}/publish`)
        return result
      }
      catch (e) {

      }
    },
    async deleteMedia ({ commit }, uuid) {
      try {
        const result = await axios.delete(`/user/post/media/${uuid}`)
        return result
      }
      catch (e) {

      }
    },
    async getUserPost ({ commit }, uuid) {
      try {
        const result = axios.get(`/user/post/${uuid}`)
        return result
      }
      catch (e) {

      }
    },
    async getMyPersonalPosts ({ commit }, cursor = null) {
      try {
        let url = '/user/my/feed/personal'
        if (!!cursor) url = `${url}?cursor=${cursor}`
        const result =  await axios.get(url)
        console.log('result11', result)
        return result
      }
      catch (e) {

      }
    },
    async getMyAdvertPosts ({ commit }, cursor = null) {
      try {
        let url = '/user/my/feed/adv'
        if (!!cursor) url = `${url}?cursor=${cursor}`
        const result =  await axios.get(url)
        return result
      }
      catch (e) {

      }
    },

    async categoryPosts ( context, { cursor = null, filters = null, category }) {
      let url = `/user/feed/category/${category}`
      if (cursor !== null) {
        url =`${url}?cursor=${cursor}`
      }
      if (filters !== null) {
        if (cursor !== null) {
          url = `${url}&json=${encodeURI(JSON.stringify({filters: filters}))}`
        } else {
          url = `${url}?json=${encodeURI(JSON.stringify({filters: filters}))}`
        }
        
      }

      try {
        const result = await axios.get(url)
        return result
      }
      catch (e) {
        return Object.assign({}, e).response
      }
    },
    getFeedFollows (context, { cursor = null }) {
      let url = '/user/subscriptions/publication'
      if (cursor !== null) {
        url =`${url}?cursor=${cursor}`
      }
      return new Promise((resolve, reject) => {
        axios.get(url)
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    getFollows (context, { cursor = null }) {
      let url = '/user/subscriptions'
      if (cursor !== null) {
        url =`${url}?cursor=${cursor}`
      }
      return new Promise((resolve, reject) => {
        axios.get(url)
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },


    getFeedFollowers (context, { cursor = null }) {
      return new Promise((resolve, reject) => {
        axios.get('/user/subscribers/publication')
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    getFollowers (context, { cursor = null }) {
      let url = '/user/subscribers'
      if (cursor !== null) {
        url =`${url}?cursor=${cursor}`
      }
      return new Promise((resolve, reject) => {
        axios.get(url)
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },



    getFeedFriends (context, { cursor = null }) {
      return new Promise((resolve, reject) => {
        axios.get('/user/friends/publication')
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    getFriends (context, { cursor = null }) {
      return new Promise((resolve, reject) => {
        axios.get('/user/friends')
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },




    getPartners (context, { cursor = null }) {
      return new Promise((resolve, reject) => {
        axios.get('/user/partners')
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },


    like (context, uuid) {
      return new Promise((resolve, reject) => {
        axios.put(`/user/post/${uuid}/like`)
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },

    getComments (context, { postUuid = null, cursor = null }) {
      let url = `/user/post/${postUuid}/comments`
      if (cursor !== null) {
        url =`${url}?cursor=${cursor}`
      }
      return new Promise((resolve, reject) => {
        axios.get(url)
        .then((response) => {
          resolve(response)
        })
        .catch(e => {
          resolve(Object.assign({}, e).response)
        })
      })
    },
    
    async updatePost ({ commit }, data ) {
      try {
        let url = `/user/post/${data.uuid}`
        const result =  await axios.put(url, data)
        return result
      }
      catch (e) {

      }
    },
    async addComment ({ commit }, { uuid = null, message = null } ) {
      try {
        let url = `/user/post/${uuid}/comments`
        const result =  await axios.post(url, { message })
        return result
      }
      catch (e) {

      }
    },
    async likeComment ({ commit }, uuid ) {
      try {
        let url = `/user/comments/${uuid}/like`
        const result =  await axios.put(url)
        return result
      }
      catch (e) {

      }
    },
    
    async search ({ commit }, { query = null, cursor = null}) {
      try {
        let url = `/search?search=${query}`
        if (!!cursor) url = `${url}&cursor=${cursor}`
        const result =  await axios.get(url)
        return result
      }
      catch (e) {

      }
    },

    async readNotification ( { commit }, uuids = []) {
      try {
        let url = `/user/notifications/read`
        console.log(uuids)
        if (uuids.length > 0) {
          console.log('REQ')
          const result =  await axios.put(url, uuids)
          return result
        }
        return null
      }
      catch (e) {

      }
    }
    
  },
}
